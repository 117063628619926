body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFCF4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*figma design*/

.left-border {
  background-image: url('./floral13.png'); /* Path to your border image */
  background-repeat: repeat-y; /* Repeat vertically */
  background-size: contain; /* Adjusts image size */
  width: 100px; /* Width of the border area */
  height: 100vh; /* Full viewport height */
  position: fixed; /* Keeps the border fixed while scrolling */
  top: 0;
  left: 0;
  /*box-shadow: 9px 0 19px rgba(92, 90, 90, 0.2)
  filter: blur(0.3px)*/
}
/*
.content {
  margin-left: 60px;  Offsets main content to the right of the border 
  padding: 10px;
}
*/



/*navbar design*/
/* Navbar container */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  /*background-color: #333;*/
  color: #fff;
  /*position: fixed;*/
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box;


  /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);*/
}


.navbar-hidden {
  transform: translateY(-100%);
}

/* Logo styling */
  .navbar-logo a {
    color: #000000;
    font-size: 1.6rem;
    text-decoration: none;
    margin-left: 130px;
    font-family: 'Newsreader', sans-serif;
    font-weight: 400;
  }

/* Link styling: about, home, my journal, sign in*/
.navbar-links {
  display: flex;
  gap: 1rem;
  list-style: none;
  margin-right:100px;
}

.navbar-links li a {
  text-decoration: none;
  font-size: 0.8rem;
  border: 1px solid;
  padding-block: 10px;
  border-radius: 5px;
  width:500px;
}
.aboutlink{
  padding-left: 26px;
  padding-right: 26px;
  color: #000000;
  background-color: #FFFCF4;
  border: 1px solid transparent !important;
  border-color: transparent !important;
}

.homelink{
  padding-left: 27px;
  padding-right: 27px;
  color: #000000;
  background-color: #FFFCF4;
  border: 1px solid transparent !important;
  border-color: transparent !important;

}

.myjournallink{
  padding-left: 13px;
  padding-right: 13px;
  color: #000000;
  background-color: #FFFCF4;
  border: 1px solid transparent !important;
  border-color: transparent !important;
}
.signinlink{
  padding-left: 23.5px;
  padding-right: 23.5px;
  /*background-color: #b4c0b5;*/
  /*background-color: #314153;  Example color */
  background-color: #697a8b;
  border: 1px solid transparent ;
  color:#000000;
  background-color: #FFFCF4;
  border: 1px solid transparent !important;
  border-color: transparent !important;
 
}

.signinlink:hover{
  background-color:#D7D1C8

}

.navbar-links li a:hover {
  /*color: #ddd;*/
  background-color:#D7D1C8;

}

/* Responsive for small screens */
@media (max-width: 375px) {

  .navbar {
    background-color: transparent;
    display:flex;
    box-sizing: border-box;
    margin-bottom: -20px;
    
  }  
 
  .navbar-links {
    flex-direction: row;
    position: absolute;
    background-color: transparent;
    box-sizing: border-box;
    font-size: 2px;
    width:300px !important;
    justify-content: right;


  }

  .navbar-links.active {
    display: flex; /* Show when active */
  }
  .navbar-links li a {
    text-decoration: none;
    font-size: 0.4rem;
    border: 1px solid;
    padding-block: 0px;
    border-radius: 5px;
    
  }
  .navbar-logo{
    margin-top: -5px !important;

  }

  .navbar-logo a {
    color: #000000;
    font-size: .5rem !important;
    text-decoration: none;
    margin-left: 0px;
    font-family: 'Newsreader', sans-serif;
    font-weight: 400;
  }
  .aboutlink{
    padding-left: 2px;
    padding-right: 2px;
    color: #000000;
    background-color: #FFFCF4;
    border: 1px solid transparent !important;
    border-color: transparent !important;
  }
  
  .homelink{
    padding-left: 2px;
    padding-right: 2px;
    color: #000000;
    background-color: #FFFCF4;
    border: 1px solid transparent !important;
    border-color: transparent !important;
  
  }
  
  .myjournallink{
    padding-left: 1px;
    padding-right: 1px;
    color: #000000;
    background-color: #FFFCF4;
    border: 1px solid transparent !important;
    border-color: transparent !important;
  }
  .signinlink{
    padding-left: 2px;
    padding-right: 2px;
    /*background-color: #b4c0b5;*/
    /*background-color: #314153;  Example color */
    background-color: #697a8b;
    border: 1px solid transparent ;
    color:#000000;
    background-color: #FFFCF4;
    border: 1px solid transparent !important;
    border-color: transparent !important;
   
  }
  

}
/*navbar ends*/

/*home*/


.appContainer {
    
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center; 
  position: relative;
  background-color: transparent;
}
.leftBorder {
  width: 3.5vw; 
  height: 100%;
}
.contentsHome {
  margin: 0 auto;
  margin-left: 3.5vw;
  margin-right: 3.5vw;

  flex: 1;
}
  .popup {
    color: #D7D1C8;
    font-size: 2vw;
    font-family: Newsreader;
    padding-block: 10px;
    text-align: center;
  }
  .journal-image {
    width: 7vw; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    padding-top: 5px;
  /*  margin-bottom: 10px;  Space between the image and the heading */
    display: block; /* Makes the image a block-level element */
    margin-left: auto; /* Centers the image horizontally */
    margin-right: auto; /* Centers the image horizontally */
    margin-bottom: -40px;
  }
  .note-title{
    margin-top:50px;
    font-family: 'Newsreader', sans-serif;
    text-align: center;
    font-size: 4.5vw;
    font-weight: 400;
  
  }
  
  .highlight {
    color: #314153; /* Example color */
  }
  .wheel-image {
    width: 600px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
  
  /*  margin-bottom: 10px;  Space between the image and the heading */
    display: block; /* Makes the image a block-level element */
    margin-left: auto; /* Centers the image horizontally */
    margin-right: auto; /* Centers the image horizontally */
    margin-bottom: -20px;
    
  }
/*
.quote{
  font-family: 'Newsreader', sans-serif;
  text-align: center;
}

.message{
  font-size: 1.5rem;

}

.author{
  font-size: 1.25rem;
  margin-top:-20px;

}
*/



@media (max-width: 1200px) {
  .spin-question {
    font-size: 1.5rem;         /* Smaller font size for screens less than 768px */
    max-width:300px;
    /*margin-left: 0px !important;*/
  }
  .wheel-container {
    margin-left:0px !important;
  }

  .wheel-image2 {
    font-size: 3.0rem;         /* Smaller font size for screens less than 768px */
    max-width: 10rem;
  }
}

.wheel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%; /* Adjust the width of the wheel */
}

.spin-set {
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Center content horizontally */
  background-color: transparent;
  margin-left: 12vw;
  margin-right:12vw;
}
/*MEME HEADER STUFF BELOW , still part of homepage*/

.spin-question{

  font-family: 'Newsreader', sans-serif;
  text-align: left;
  font-size: 4.5vw;
  font-weight: 400;
  max-width:33rem;
  background-color: transparent;
  

}

.wheel-image2 {
 
/*  margin-bottom: 10px;  
  display: block; 
  margin-left: auto; 
  margin-right: auto; 
  margin-bottom: -20px;
  */
  width: 30vw;
  height:auto;
  padding:0px;
  background-color: transparent;
  cursor: pointer;

}



.form button {
  background: none; /* Remove button background */
  border: none; /* Remove button border */
  padding: 0; /* Remove extra spacing */
  outline: none; /* Remove outline on focus */
}

.organisation-container {
  display: flex;              /* Enables flexbox */
  flex-direction: column;     /* Aligns children (image and text) vertically */

  background-color: transparent;
  /*margin-left: 9vw;
  margin-right: 9vw;*/
  max-width: 1100px;
  
  margin: 0 auto;

}

  .meme--text {
    text-align: left;         /* Centers the text inside the container */
    font-family: 'Newsreader', sans-serif; /* Optional, depending on your meme design */
    font-size: 5vw;            /* Adjust text size as needed */
    color: rgb(0, 0, 0);               /* Make the text white if needed */
    letter-spacing: 1px;        /* Optional: Adds spacing between letters */
    font-weight: 400;

  }
  /* this part was added to remove images regardless of screen sizes*/
  .meme--image{
    display:none
  }
  /*
  @media (max-width: 1250px) {
   
    .meme--image{
      display:none
    }
  }*/

    @media (max-width: 800px) {
  
    .organisation-card-text{
      background-color: #6f6f22;
      color: #000000;
      max-width:auto;
      border-radius: 14px;
      position: relative;
     
    }
      .org-info {
        font-size: 1rem !important;            /* Adjust text size as needed */
        color: rgb(0, 0, 0);               /* Make the text white if needed */
        margin-left: 1.85rem !important;          /* Adds space above the text */
        padding-top: 1.1rem !important;
      }
      .donate-button{
        font-size: 0.8rem !important;
        margin-left: 1.85rem !important; 
      }
    
  }

  .organisation-card {
    display: flex;              /* Enables flexbox */
    flex-direction: row;     /* Aligns children (image and text) vertically */
    margin-left: auto; 
    margin-right: auto; 
    background-color: white;
    border-radius: 14px;
    position: relative;
    padding-block: 10px;

   /* max-width:1100px;*/


  }
    .meme--image {
      width: 100%;                /* Ensures the image is responsive within its parent */
      max-width: 400px;            /* Prevents the image from overflowing */
      height: auto; 
      background-color: aqua;              /* Maintains aspect ratio */
      border-top-left-radius: 14px; 
      border-bottom-left-radius: 14px;
    }
    .organisation-card-text{
      background-color: #F5F0E3;
      color: #000000;
      max-width:auto;
      border-radius: 14px;
      position: relative;
     
    }
    
      /*.fas {
        font-size: 24px; /* Adjust the size of the icon 
        border: solid 3px;
        border-color: #000000;
        border-radius: 50%;
        background-color: #000000;
        margin-left: 1.86rem;         
        margin-top:  15px;
      }*/
      .org-info {
        text-align: left;         /* Centers the text inside the container */
        font-family: sans-serif; /* Optional, depending on your meme design */
        font-size: 1.3rem;            /* Adjust text size as needed */
        color: rgb(0, 0, 0);               /* Make the text white if needed */
        margin-left: 3.85rem;          /* Adds space above the text */
        margin-right: 3.85rem;
        padding-top: 1.95rem;
        line-height: 1.8;
        padding-bottom: 20px;
        
      }
      .donate-button{
        background-color: #E9E1C8;
        font-size: 1rem;
        color:#333;
       /*border: solid 0.5px #E1DDD8;*/
       border:transparent;
        padding-block:14px;
        padding-left:35px;
        padding-right:35px;
        margin-left: 3.85rem; 
        margin-bottom: 40PX;
        border-radius: 12px;

      }


      @media (max-width: 375px) {
        .org-info {
          font-size: 0.5rem !important;            /* Adjust text size as needed */
          color: rgb(0, 0, 0);               /* Make the text white if needed */
          padding-top: .5rem !important;
          text-align: left;         /* Centers the text inside the container */
          margin-left: 2rem !important;          /* Adds space above the text */
          margin-right: 2rem !important;
          line-height: 1.8;
          padding-bottom: 0px;
          box-sizing: border-box;
        }
    
        .organisation-container {
          display: flex;              /* Enables flexbox */
          flex-direction: column;     /* Aligns children (image and text) vertically */
        
          background-color: transparent;
          max-width:320px;
          border-radius: 5px;
        
      
        }
        .meme--text{
          font-size: 15px;
        }
        .spin-set {
    
          margin-left: 2vw;
          margin-right:2vw;
        }
        .appContainer {
    
          margin: 0 auto;
          margin-left: 0vw;
          padding-top: 20px !important;

          margin-right: 0vw;
          padding: 0px;
          font-family: Arial, sans-serif;
          display: flex;
          align-items: center; 
          position: relative;
        }
        .contentsHome {
          margin: 0 auto;
          margin-left: 3.5vw;
          margin-right: 1.75vw;
        
          flex: 1;
        }
        .journal-image {
          width: 7vw; /* Adjust the width as needed */
          height: auto; /* Maintain aspect ratio */
          padding-top: 5px;
        /*  margin-bottom: 10px;  Space between the image and the heading */
          display: block; /* Makes the image a block-level element */
          margin-left: auto; /* Centers the image horizontally */
          margin-right: auto; /* Centers the image horizontally */
          margin-bottom: -40px;
        }

        .donate-button{
          background-color: #E9E1C8;
          font-size: .5rem !important;
          color:#333;
         /*border: solid 0.5px #E1DDD8;*/
         border:transparent;
          padding-block:6px;
          padding-left:15px;
          padding-right:15px;
          margin-left: 3.85rem; 
          bottom: 80px; 
          margin-bottom: 20PX;
          border-radius:8px;
  
        }
        .spin-set {
          display: flex;
          align-items: center; /* Vertically center the content */
          justify-content: center; /* Center content horizontally */
          background-color: transparent;
          margin-left: 3.5vw;
          margin-right:3.5vw;
        }
        /*MEME HEADER STUFF BELOW , still part of homepage*/
        
        .spin-question{
        
          font-family: 'Newsreader', sans-serif;
          text-align: left;
          font-size: 4.5vw;
          font-weight: 400;
          max-width:33rem;
          background-color: transparent;
          
        
        }
        
      }


/* making the wheel spin*/
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wheel-image2.spin {
  animation: spin 1.3s linear;
}




/*About Page*/

.left-border {
  width: 3.5vw;
  height: 100%;
}


  .AboutPageContentsContainer {
    margin: 0 auto;
    margin-left: 3.5vw;
    margin-right: 3.5vw;
    font-family: Newsreader, sans-serif;
    display:flex;
    align-items: center;
    margin-bottom: 100px;
    
  }

    .AboutPageContents {
   
      margin: 0 auto;
      margin-left: 3.5vw;
      flex: 1;
      padding-top: 22px;
      font-family: Newsreader;
      font-weight: 400;
      background-color: transparent;
    }

      .descriptionText {
        font-size: 1.2rem;
        line-height: 30px;
        font-family: sans-serif;
        /*backgroundColor: '#F5F0E3',
        //padding: '20px',*/
        border-radius: 12px;
        /*//color: '#655656',*/
        color: black; 

      }

      .aboutOrganisationTitle {
        flex: 1;
        font-family: Newsreader;
        font-weight: 400;
        font-size: 2.8rem;
        margin-bottom: 0px;
        position: relative;

      }
  
      .containerAbout {
        display: flex;
        flex-Wrap: wrap;
        justify-content: center;
        /*padding: '20px',*/
      }

 
        .cardAbout {
          /*border: '1px solid #ddd',*/
          border-radius: 12px;
          padding: 25px;
          margin: 5px;
          max-width: 300px;
          background-color: #F5F0E3;
          /*backgroundColor: '#D7D1C8',*/
          /*boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',*/
        }
          .imageAbout {
              width: 100%;
              height: auto;
              border-radius: 8px;
          }
          .nameAbout {
            font-size: 2.2rem;
            font-weight: 400;

          }
          .infoAbout {
            font-size: 1rem;
            line-height: 25px;
            font-family: sans-serif;
            margin-top:-20px;
    

          }

          .learnMoreAbout {
            margin-bottom: 15px;
            font-family: sans-serif;
            color: #919191;
            display:block;
            text-decoration: none;
          }

          .donateLinkAbout {
            /*
              color: '#007BFF',
              textDecoration: 'none',
              fontWeight: 'bold',
              backgroundColor: 'black',
              */
              background-color: #E9E1C8;
              text-decoration: none;
              font-size: 1rem;
              color:#655656;
              border: none;
              padding-block:12px;
              padding-left: 30px;
              padding-right:30px;
              width:3rem;
              text-align: center;
              display:block;
              
              /*//bottom: '80px', 
              //marginBottom: '20px',
              */
              border-radius: 12px;
              font-family: sans-serif;
          }

          @media (max-width: 375px) {
            .AboutPageContentsContainer {
              margin: 0 auto;
              margin-left: 3.5vw;
              margin-right: 3.5vw;
              font-family: Newsreader, sans-serif;
              display:flex;
              align-items: center;
              margin-bottom: 100px;
              
            }
    
              .AboutPageContents {
             
                margin: 0 auto;
                margin-left: 3.5vw;
                flex: 1;
                padding-top: 20px;
                font-family: Newsreader;
                font-weight: 400;
                background-color: transparent;
              }
          
                .descriptionText {
                  font-size: .6rem;
                  line-height: 18px;
                  font-family: sans-serif;
                  /*backgroundColor: '#F5F0E3',
                  //padding: '20px',*/
                  border-radius: 12px;
                  /*//color: '#655656',*/
                  color: black; 
          
                }
          
                .aboutOrganisationTitle {
                  flex: 1;
                  font-family: Newsreader;
                  font-weight: 400;
                  font-size: 1rem;
                  margin-bottom: 0px;
                  position: relative;
          
                }
            
                .containerAbout {
                  display: flex;
                  flex-Wrap: wrap;
                  justify-content: center;
                  /*padding: '20px',*/
                }
          
           
                  .cardAbout {
                    /*border: '1px solid #ddd',*/
                    border-radius: 12px;
                    padding: 13px;
                    margin: 5px;
                    max-width: 300px;
                    background-color: #F5F0E3;
                    /*backgroundColor: '#D7D1C8',*/
                    /*boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',*/
                  }
                    .imageAbout {
                        width: 100%;
                        height: auto;
                        border-radius: 8px;
                    }
                    .nameAbout {
                      font-size: 1rem;
                      font-weight: 400;
          
                    }
                    .infoAbout {
                      font-size: .6rem;
                      line-height: 18px;
                      font-family: sans-serif;
                      margin-top: 0px;
              
          
                    }
          
                    .learnMoreAbout {
                      margin-bottom: 15px;
                      font-family: sans-serif;
                      color: #919191;
                      display:block;
                      text-decoration: none;
                      font-size:.6rem;
                    }
          
                    .donateLinkAbout {
                      /*
                        color: '#007BFF',
                        textDecoration: 'none',
                        fontWeight: 'bold',
                        backgroundColor: 'black',
                        */
                        background-color: #E9E1C8;
                        text-decoration: none;
                        font-size: .6rem;
                        color:#655656;
                        border: none;
                        padding-block:6px;
                        padding-left: 10px;
                        padding-right:10px;
                        width:3rem;
                        text-align: center;
                        display:block;
                        
                        /*//bottom: '80px', 
                        //marginBottom: '20px',
                        */
                        border-radius: 12px;
                        font-family: sans-serif;
                    }
          

          }


  /*Journal page*/

    .entryBigContainer {
      margin-top:-20px;
  
    }
    .entryContainer {
      /*border: '1px solid #655656',*/
      padding-top: 1px;
      padding-bottom: 13px;
      padding-left:18px;
      padding-right:18px;
      margin-bottom: 10px;
      border-radius: 5px;
      font-size: 1rem;
      font-family: sans-serif;
      background-color: #F5F0E3;
      position: relative;
      transition: all 0.3s ease;
  
    }
    .entryComponents {
      display: flex;
      flex-direction: row;
      position: relative;
      /*backgroundColor: 'red',*/
      top: 0px;
    }

    .entryText {
      font-size: 16px;
      color: #655656;
      
    }
    .entryDate {
      font-size: 12px;
      color: #888;
      position: absolute;
      bottom: 0px;
      opacity: 0; /* Default is hidden */
      transition: opacity 0.3s ease;
    }
    
    .entryDate.hovered {
      opacity: 1; /* Show on hover */
    }
    .deleteButton {
      background-color: transparent;
      color: #655656;
      border-radius: 4px;
      border: transparent;
      padding: 5px;
      cursor: pointer;
      position: absolute;
      right: 16px;
      display: block;
      top: 10px;
      font-size: 1rem;
      width: 1rem;
      height: 1rem;
      justify-content: center;
      opacity: 0; /* Default is hidden */
      transition: opacity 0.3s ease;
    }
    
    .deleteButton.hovered {
      opacity: 1; /* Show on hover */
    }
  
    .RestoreArchiveButton {
      color: #655656;
      background-color: transparent;
      border: none;
      border-radius: 4px;
      padding: 5px;
      width: 1rem;
      height: 1rem;
      position: absolute;
      font-size: 1rem;
      right: 45px;
      top: 10px;
      cursor: pointer;
      opacity: 0; /* Default is hidden */
      transition: opacity 0.3s ease;
    }
    
    .RestoreArchiveButton.hovered {
      opacity: 1; /* Show on hover */
    }

    @media (max-width: 375px) {
      .entryBigContainer {
        margin-top:-8px;
    
      }
      .entryContainer {
        /*border: '1px solid #655656',*/
        padding-top: 1px;
        padding-bottom: 10px;
        padding-left:18px;
        padding-right:18px;
        border-radius: 5px;
        font-size: .3rem !important;
        font-family: sans-serif;
        background-color: #F5F0E3;
        position: relative;
        transition: all 0.3s ease;

    
      }
      .entryComponents {
        display: flex;
        flex-direction: row;
        position: relative;
        /*backgroundColor: 'red',*/
        top: 0px;
        
      }
  
      .entryText {
        font-size: 8px;
        color: #655656;
        
      }
      .entryDate {
        font-size: 7px;
        color: #888;
        position: absolute;
        bottom: 0px;
        opacity: 0; /* Default is hidden */
        transition: opacity 0.3s ease;
      }
      
      .entryDate.hovered {
        opacity: 1; /* Show on hover */
      }
      
      .deleteButton .material-icons {
        font-size: .8rem; /* Adjust icon size */
        width: .8rem; /* Optional: set width */
        height: .8rem; /* Optional: set height */
      
      }
      
      .deleteButton {
        background-color: transparent;
        color: #655656;
        border-radius: 4px;
        border: transparent;
        padding: 5px;
        cursor: pointer;
        position: absolute;
        right: 2px;
        display: block;
        top: 3px;
        justify-content: center;
        opacity: 0; /* Default is hidden */
        transition: opacity 0.3s ease;
      }
      
      .deleteButton.hovered {
        opacity: 1; /* Show on hover */
      }
      
    
      .RestoreArchiveButton {
        color: #655656;
        background-color: transparent;
        border: none;
        border-radius: 4px;
        padding: 5px;
        position: absolute;
        right: 17px;
        top: 3px;
        cursor: pointer;
        opacity: 0; /* Default is hidden */
        transition: opacity 0.3s ease;
      }
      
      .RestoreArchiveButton.hovered {
        opacity: 1; /* Show on hover */
      }

      .RestoreArchiveButton .material-icons {
        font-size: .8rem; /* Adjust icon size */
        width: .8rem; /* Optional: set width */
        height: .8rem; /* Optional: set height */
      
      }
      
    }
    /*Journal NOT THE ENTRYLIST*/
    .appContainer {
      margin: 0 auto;
      margin-left: 4.5vw;
      margin-right: 1.5vw;
      font-family: 'Newsreader, sans-serif';
      display: flex;
      align-items: center;
      margin-bottom: 100px;
    }
    
    .leftBorder {
      width: 3.5vw;
      height: 100%;
    }
    
    .contentsHome2 {
      /*margin-left: 3vw;
      margin-right: 1vw;*/
      margin: 0 auto;
      flex: 1;
      padding-top: 0px;
      font-family: 'Newsreader';
      font-weight: 400;
    }
    
    .contentsHome2Welcome {
      font-size: 1.8vw;
    }
    
    .contentsHome2p1,
    .contentsHome2p2 {
      font-size: 2.8rem;
      margin-bottom: 0px;
      position: relative;
    }
    
    .month_tracker {
      font-size: 2rem;
      color: #655656;
    }
    
    .ArchiveToggleSet {
      position: relative;
    }
    
    .ArchiveToggle {
      background-color: #F5F0E3;
      left: 0rem;
      top: 3rem;
      border-radius: 5px;
      border: transparent;
      padding: 8px;
      cursor: pointer;
      color: #655656;
    }
    
    .LogOutButton {
      background-color: #F5F0E3;
      position: absolute;
      border-radius: 5px;
      border: transparent;
      padding: 8px;
      cursor: pointer;
      margin-top: 60px;
      color: #655656;
    }
    @media(max-width: 375px){
      .appContainer {
        margin: 0 auto;
        margin-left: 0vw;
        margin-right: 0vw;
        font-family: 'Newsreader, sans-serif';
        display: flex;
        align-items: center;
        margin-bottom: 100px;
      }
      
      .leftBorder {
        width: 3.5vw;
        height: 100%;
      }
      
      .contentsHome2 {
        margin-left: 6.5vw !important;
        margin-right: 3.5vw !important;
        margin: 0 auto;
        flex: 1;
        padding-top: 5px;
        font-family: 'Newsreader';
        font-weight: 400;
      }
      
      .contentsHome2Welcome {
        font-size: .7rem;
      }
      
      .contentsHome2p1,
      .contentsHome2p2 {
        font-size: 1rem;
        margin-bottom: 0px;
        position: relative;
      }
      
      .month_tracker {
        font-size: .8rem;
        color: #655656;
      }
      
      .ArchiveToggleSet {
        position: relative;
      }
      
      .ArchiveToggle {
        background-color: #F5F0E3;
        left: 0rem;
        top: 3rem;
        border-radius: 5px;
        border: transparent;
        padding: 8px;
        cursor: pointer;
        color: #655656;
        margin-top:10px;
        font-size:8px;
      }
      
      .LogOutButton {
        background-color: #F5F0E3;
        position: absolute;
        border-radius: 5px;
        border: transparent;
        padding: 8px;
        cursor: pointer;
        margin-top: 50px;
        color: #655656;
        font-size:8px;
      }

    }
    
  
  /*sign in page*/

  .signContainer {
  margin: 0 auto;
  font-family: "Newsreader", sans-serif;
  display: flex;
  align-items: center;
  background-color: #fffcf4;
  height: 900px;
}

.miniContainer {
  background-color: #faf6eb;
  max-width: 500px;
  margin-left: 10px;
  margin-right: 10px;
  height: 250px;
  margin: auto;
  flex: 1;
  padding-bottom: 130px;
  font-family: "Newsreader", sans-serif;
  font-weight: 400;
  text-align: center;
  justify-content: center;
  position: relative;
  display: flex; /* Enable flexbox */
  flex-direction: column; /* Ensure content stacks vertically */
  align-items: center; /* Center children horizontally */
  border-radius: 9px;
  box-shadow: 2px 2px 2px #e1ddd8;
  border: 0.5px solid #e1ddd8;
  margin-top: 200px;
}

.Title {
  text-align: center; /* Corrected from justifyText */
  font-size: 2.5rem;
  font-family: sans-serif;
  margin-bottom: -2px;
}

.TitleText {
  text-align: center; /* Corrected from justifyText */
  font-size: 1rem;
  font-family: sans-serif;
  margin-bottom: 30px;
}

.GoogleButton {
  background-color: #FCF8EF;
  color: black;
  border-radius: 5px;
  font-size: 13px;
  box-shadow: none;
  border: 0.5px solid #e1ddd8;
}

@media(max-width:375px){

  .signContainer {
    margin: 0 auto;
    font-family: "Newsreader", sans-serif;
    display: flex;
    align-items: center;
    background-color: #fffcf4;
    height: 900px;
  }
  
  .miniContainer {
    background-color: #faf6eb;
    max-width: 300px;
    margin-left: 10px;
    margin-right: 10px;
    height: 160px;
    margin: auto;
    flex: 1;
    padding-top: 20px;
    padding-bottom: 120px;
    font-family: "Newsreader", sans-serif;
    font-weight: 400;
    text-align: center;
    justify-content: center;
    position: relative;
    display: flex; /* Enable flexbox */
    flex-direction: column; /* Ensure content stacks vertically */
    align-items: center; /* Center children horizontally */
    border-radius: 9px;
    box-shadow: 2px 2px 2px #e1ddd8;
    border: 0.5px solid #e1ddd8;
    margin-top: 150px;
  }
  
  .Title {
    text-align: center; /* Corrected from justifyText */
    font-size: 1.5rem;
    font-family: sans-serif;
    margin-bottom: 0px;
  }
  
  .TitleText {
    text-align: center; /* Corrected from justifyText */
    font-size: .8rem;
    font-family: sans-serif;
    margin-bottom: 30px;
    
  }
  /*
  button.GoogleButton {
    background-color: #FCF8EF;
    color: black;
    border-radius: 5px;
    font-size: 20px;
    box-shadow: none;
    border: 0.5px solid #e1ddd8;
  }
*/
}



